<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        x-large
        width="160"
        class="mt-5"
      >
        Enviar
      </v-btn>
    </template>
    <v-form ref="revaloraform">
      <v-card class="pt-10 pr-9 pl-9 pb-10">
        <v-card-title>
          <v-row class="justify-center align-center">
            <span class="text-body-2"
              >Escríbenos y te contactaremos a la brevedad</span
            >
          </v-row>
        </v-card-title>
        <v-card-text class="mt-10">
          <v-row>
            <v-col cols="12" sm="6">
              <revalora-text-field
                label="Nombre y apellidos"
                outlined
                v-model="form.name"
                :rules="[rules.required]"
                hint="Este campo es requerido"
                :persistentHint="true"
              ></revalora-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <revalora-text-field
                label="Email"
                outlined
                v-model="form.email"
                :rules="[rules.required, rules.email]"
                hint="Este campo es requerido"
                :persistentHint="true"
              ></revalora-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <revalora-text-field
                label="Teléfono de contacto"
                :rules="[
                  rules.required,
                  (value) => rules.maxLength(value, 9),
                  (value) => rules.minLength(value, 9),
                ]"
                :formatters="[formatters.onlyDigits]"
                v-model="form.phone"
                :maxLength="9"
                :prefix="'+56'"
                hint="Este campo es requerido"
                :persistentHint="true"
                outlined
              ></revalora-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <revalora-text-field
                label="Empresa (opcional)"
                v-model="form.company"
                outlined
              ></revalora-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :items="sellerList"
                v-model="form.seller"
                label="Ejecutivo Revalora"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Mensaje"
                placeholder="Escriba acá su mensaje, información adicional, requerimientos"
                outlined
                v-model="form.message"
              ></v-textarea>
            </v-col>
          </v-row>
          <file-input
            :sendData="sendData"
            @input="updateUploadFiles"
            :resetFiles="resetFiles"
          ></file-input>
        </v-card-text>
        <v-card-actions>
          <v-row class="justify-space-around align-center">
            <v-btn color="red" dark @click="dialog = false" width="100">
              Volver
            </v-btn>
            <v-btn
              color="primary"
              @click="handleSubmit"
              :loading="loading"
              width="100"
              :disabled="!enableSendButton"
            >
              Enviar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
    <modify-pdf
      ref="modify"
      :seller="form.seller"
      @revaloraPdf="addPdf"
    ></modify-pdf>
  </v-dialog>
</template>

<script>
import RevaloraTextField from "../UI/RevaloraTextField";
import ModifyPdf from "@/components/utils/ModifyPdf.vue";
import httpClient from "@/services/httpClient";
import form from "@/mixins/form";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Vue from "vue";
Vue.use(VueReCaptcha, { siteKey: "6LfJz3EiAAAAAAgSGn6Ot0cUhJG27DXWIVIWZQn4" });

import FileInput from "@/components/Form/FileInput";
import { bus } from "@/main";

export default {
  name: "CubicatorForm",
  components: {
    RevaloraTextField,
    FileInput,
    ModifyPdf,
  },
  mixins: [form],
  watch: {
    "form.seller"() {
      bus.$emit("sellerSelected", this.form.seller);
      this.$refs.modify.modifyPdf();
    },
  },
  computed: {
    enableSendButton() {
      return (
        this.form.name.length > 0 &&
        this.form.email.length > 0 &&
        this.form.phone.length > 0 &&
        this.form.seller.length > 0
      );
    },
  },
  data: () => ({
    dialog: false,
    form: {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      seller: "Sin ejecutivo",
    },
    sellerList: [
      "Sin ejecutivo",
      "Fernando Maurin",
      "Alicia Lozano",
      "Nicolas Maurin",
      "Nicole Ianizsewski",
      "Maria Jesus Martinez",
      "Andrea Montoya",
      "Maria Jesus Toledo",
    ],
    showSendButton: false,
    uploadFiles: [],
    sendData: false,
    loading: false,
    formOk: false,
    showInfoDialog: false,
    pdfRevalora: "null",
    errorSendMail: false,
    resetFiles: false,
  }),
  methods: {
    updateUploadFiles(files) {
      this.uploadFiles = files;
    },
    addPdf(revaloraPdf) {
      this.pdfRevalora = revaloraPdf;
    },
    resetForm() {
      this.form.name = "";
      this.form.phone = "";
      this.form.email = "";
      this.form.company = "";
      this.form.message = "";
      this.form.seller = "Sin ejecutivo";
      this.uploadFiles = [];
      this.resetFiles = true;
      this.$refs.revaloraform.resetValidation();
    },
    handleSubmit() {
      this.sendData = true;

      const formData = new FormData();

      formData.append("from", this.form.email);
      formData.append("subject", "Solicitud de cotización tienda revalora");
      formData.append("name", this.form.name);
      formData.append("phone", this.form.phone);
      formData.append("seller", this.form.seller);
      formData.append("company", this.form.company);
      formData.append("message", this.form.message);
      formData.append(
        "file",
        this.pdfRevalora,
        "Cotización Deck - Revalora.pdf"
      );
      this.uploadFiles.forEach((file) => {
        formData.append("file", file);
      });

      this.loading = true;
      this.errorSendMail = true;
      setTimeout(() => {
        if (this.errorSendMail) {
          this.loading = false;
          this.sendData = false;
          bus.$emit("showInfoDialog", {
            formOk: false,
            showInfoDialog: true,
          });
        }
      }, 10000);
      this.$recaptcha("login").then((token) => {
        if (token) {
          httpClient
            .post("https://api.thegrouplab.com/v1/contact-form", formData)
            .then((data) => {
              if (data) {
                this.loading = false;
                bus.$emit("showInfoDialog", {
                  formOk: true,
                  showInfoDialog: true,
                });
                this.dialog = false;
                this.errorSendMail = false;
                this.sendData = false;
                this.resetForm();
              }
            })
            .catch((err) => {
              if (err) {
                this.loading = false;
                bus.$emit("showInfoDialog", {
                  formOk: false,
                  showInfoDialog: true,
                });
                this.errorSendMail = false;
              }
              this.sendData = false;
              this.dialog = false;
            });
        }
      });
    },
  },
  mounted() {
    bus.$emit("sellerSelected", this.form.seller);
  },
  created() {
    bus.$on("retryForm", () => {
      this.dialog = true;
    });
  },
};
</script>

<style>
</style>