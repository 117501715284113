<template>
  <!-- <v-btn color="primary" @click="modifyPdf" x-large width="160" class="mt-5"
    >Descargar</v-btn
  > -->
  <div></div>
</template>

<script>
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import templatePdf from "/public/pdf-descarga.pdf";
import axios from "axios";

import { mapGetters } from "vuex";

export default {
  name: "modifyPdf",
  props: {
    seller: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      "table/getSelectedTable",
      "table/getSelectedColor",
      "table/getAttachment",
      "result/getLength",
      "result/getWidth",
      "result/getAdditionalMeters",
      "result/getResult",
      "result/getFramework",
      "result/getAttachment",
      "result/getWithFramework",
      "result/priceDeck",
      "result/priceFramework",
      "result/totalPrice",
      "result/attachmentPrice",
    ]),
    length() {
      return this["result/getLength"];
    },
    width() {
      return this["result/getWidth"];
    },
    additionalMeters() {
      return this["result/getAdditionalMeters"];
    },
    result() {
      return this["result/getResult"];
    },
    framework() {
      return this["result/getFramework"];
    },
    attachment() {
      return this["result/getAttachment"];
    },
    withFramework() {
      return this["result/getWithFramework"];
    },
    selectedTable() {
      return this["table/getSelectedTable"];
    },
    tableAttachment() {
      return this["table/getAttachment"];
    },
    selectedColor() {
      return this["table/getSelectedColor"];
    },
    priceDeck() {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      })
        .format(this["result/priceDeck"])
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
    },
    priceFramework() {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      })
        .format(this["result/priceFramework"])
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
    },
    totalPrice() {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      })
        .format(this["result/totalPrice"])
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
    },
    attachmentPrice() {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      })
        .format(this["result/attachmentPrice"])
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
    },
  },
  data: () => ({
    templatePdf,
    date: null,
  }),
  methods: {
    async modifyPdf() {
      let url;

      if (
        !this.withFramework &&
        (this.selectedTable.sku === "TR05" || this.selectedTable.sku === "TR06")
      )
        url = "https://revalora.s3.amazonaws.com/pdf/Recurso-1.pdf";

      if (
        (this.withFramework &&
          (this.selectedTable.sku === "TR05" ||
            this.selectedTable.sku === "TR06")) ||
        (!this.withFramework &&
          (this.selectedTable.sku === "TR05R" ||
            this.selectedTable.sku === "TR06R"))
      )
        url = "https://revalora.s3.amazonaws.com/pdf/Recurso-2.pdf";

      if (this.withFramework && this.selectedTable.name.includes("Ranurada")) {
        url = "https://revalora.s3.amazonaws.com/pdf/Recurso-3.pdf";
      }

      const existingPdfBytes = await axios
        .get(url, {
          responseType: "blob",
        })
        .then((result) => result.data.arrayBuffer())
        .catch((err) => {
          console.error(err);
        });
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const helveticaOblique = await pdfDoc.embedFont(
        StandardFonts.HelveticaOblique
      );

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      //seller
      firstPage.drawText(`${this.seller}`, {
        x: 200,
        y: 556,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      //length
      firstPage.drawText(`${this.trunc(this.length, 3)}`, {
        x: 125,
        y: 475,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      //width
      firstPage.drawText(`${this.trunc(this.width, 3)}`, {
        x: 285,
        y: 475,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      //additionalMeters
      firstPage.drawText(`${this.trunc(this.additionalMeters, 3)}`, {
        x: 440,
        y: 475,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      //deck SKU -- first row/first column
      firstPage.drawText(
        `${this.selectedTable.sku}${this.selectedColor.skuColor}
        `,
        {
          x: 65,
          y: 353,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        }
      );
      //Principal deck -- first row/second column
      firstPage.drawText(`${this.selectedTable.name}`, {
        x: 140,
        y: 353,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      //Cantidad -- first row/third column
      firstPage.drawText(`${this.result}`, {
        x: 270,
        y: 353,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      //deck color -- first row/fourth column
      firstPage.drawText(`${this.selectedColor.name}`, {
        x: 320,
        y: 353,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      //tablePrice -- first row/5th column
      firstPage.drawText(
        `${new Intl.NumberFormat("es-CL", {
          style: "currency",
          currency: "CLP",
        })
          .format(this.selectedTable.price)
          .replace(/^(\D+)/, "$1 ")
          .replace(/\s+/, " ")} `,
        {
          x: 395,
          y: 353,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        }
      );
      //deck Price -- first row/6th column
      firstPage.drawText(`${this.priceDeck} `, {
        x: 470,
        y: 353,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      if (
        !this.withFramework &&
        (this.selectedTable.sku === "TR05" || this.selectedTable.sku === "TR06")
      ) {
        //total Price -- first row/6th column
        firstPage.drawText(`${this.totalPrice} `, {
          x: 470,
          y: 325,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        });
      }
      if (
        (this.selectedTable.name.includes("Lisa") && this.withFramework) ||
        (this.selectedTable.name.includes("Ranurada") && !this.withFramework) ||
        (this.selectedTable.name.includes("Ranurada") && this.withFramework)
      ) {
        //framework SKU -- second row/first column
        firstPage.drawText(
          `${
            this.selectedTable.name.includes("Lisa")
              ? `${this.selectedTable.frameworkSku}${this.selectedColor.skuColor}`
              : this.tableAttachment.sku
          }`,
          {
            x: 65,
            y: 325,
            size: 11,
            font: helveticaFont,
            color: rgb(0.439, 0.435, 0.435),
          }
        );
        //Framework description -- second row/second column
        firstPage.drawText(
          `${
            this.selectedTable.name.includes("Lisa")
              ? `Tablas entramado`
              : this.tableAttachment.name
          }`,
          {
            x: 140,
            y: 325,
            size: 11,
            font: helveticaFont,
            color: rgb(0.439, 0.435, 0.435),
          }
        );
        //framework cantidad -- second row/third column
        firstPage.drawText(
          `${
            this.selectedTable.name.includes("Lisa")
              ? this.framework
              : this.attachment
          }`,
          {
            x: 270,
            y: 325,
            size: 11,
            font: helveticaFont,
            color: rgb(0.439, 0.435, 0.435),
          }
        );
        //Framework color -- second row/fourth column
        firstPage.drawText(`${this.selectedColor.name}`, {
          x: 320,
          y: 325,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        });
        //tablePrice -- second row/5th column
        firstPage.drawText(
          `${
            this.selectedTable.name.includes("Lisa")
              ? `${new Intl.NumberFormat("es-CL", {
                  style: "currency",
                  currency: "CLP",
                })
                  .format(this.selectedTable.frameworkPrice)
                  .replace(/^(\D+)/, "$1 ")
                  .replace(/\s+/, " ")} `
              : `${new Intl.NumberFormat("es-CL", {
                  style: "currency",
                  currency: "CLP",
                })
                  .format(this.tableAttachment.price)
                  .replace(/^(\D+)/, "$1 ")
                  .replace(/\s+/, " ")} `
          }`,
          {
            x: 395,
            y: 325,
            size: 11,
            font: helveticaFont,
            color: rgb(0.439, 0.435, 0.435),
          }
        );
        //Framework price -- second row/6th column
        firstPage.drawText(
          `${
            this.selectedTable.name.includes("Lisa")
              ? `${this.priceFramework} `
              : `${this.attachmentPrice} `
          }`,
          {
            x: 470,
            y: 325,
            size: 11,
            font: helveticaFont,
            color: rgb(0.439, 0.435, 0.435),
          }
        );
        //total Price -- first row/6th column
        if (!this.selectedTable.name.includes("Ranurada")) {
          firstPage.drawText(`${this.totalPrice} `, {
            x: 470,
            y: 296,
            size: 11,
            font: helveticaFont,
            color: rgb(0.439, 0.435, 0.435),
          });
        }
      }
      if (this.selectedTable.name.includes("Ranurada") && this.withFramework) {
        //deck SKU -- third row/first column
        firstPage.drawText(
          `${this.selectedTable.frameworkSku}${this.selectedColor.skuColor}`,
          {
            x: 65,
            y: 296,
            size: 11,
            font: helveticaFont,
            color: rgb(0.439, 0.435, 0.435),
          }
        );
        //Principal deck -- third row/second column
        firstPage.drawText(`Tablas entramado`, {
          x: 140,
          y: 296,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        });
        //cantidad
        firstPage.drawText(`${this.framework}`, {
          x: 270,
          y: 296,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        });
        //deck color -- third row/third column
        firstPage.drawText(`${this.selectedColor.name}`, {
          x: 320,
          y: 296,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        });
        //tablePrice -- second row/5th column
        firstPage.drawText(
          `${new Intl.NumberFormat("es-CL", {
            style: "currency",
            currency: "CLP",
          })
            .format(this.selectedTable.frameworkPrice)
            .replace(/^(\D+)/, "$1 ")
            .replace(/\s+/, " ")} `,
          {
            x: 395,
            y: 296,
            size: 11,
            font: helveticaFont,
            color: rgb(0.439, 0.435, 0.435),
          }
        );
        //Framework price -- second row/6th column
        firstPage.drawText(`${this.priceFramework} `, {
          x: 470,
          y: 296,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        });
        //total Price -- first row/6th column
        firstPage.drawText(`${this.totalPrice} `, {
          x: 470,
          y: 268,
          size: 11,
          font: helveticaFont,
          color: rgb(0.439, 0.435, 0.435),
        });
      }
      //date
      firstPage.drawText(`${this.date}`, {
        x: 500,
        y: 615,
        size: 11,
        font: helveticaFont,
        color: rgb(0.439, 0.435, 0.435),
      });
      //iva **
      firstPage.drawText(`**`, {
        x: 53,
        y: 288,
        size: 8,
        font: helveticaOblique,
        color: rgb(0, 0.529, 0.537),
      });
      //iva legend
      firstPage.drawText(`Los valores no incluyen IVA`, {
        x: 60,
        y: 288,
        size: 9,
        font: helveticaOblique,
        color: rgb(0, 0.529, 0.537),
      });
      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], {
        type: "application/pdf",
      });
      // this.downloadBlob(blob, "revaloraPdf", "application/pdf");
      this.$emit("revaloraPdf", blob);
    },
    downloadBlob(data, fileName, mimeType) {
      const blob = new Blob([data], {
        type: mimeType,
      });

      const url = window.URL.createObjectURL(blob);

      this.downloadURL(url, fileName);

      setTimeout(() => window.URL.revokeObjectURL(url), 1000);
    },
    downloadURL(data, fileName) {
      const a = document.createElement("a");
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.style.display = "none";
      a.click();
      a.remove();
    },
    formatDate(date) {
      date = date.split("/");
      date = date.reverse();
      date = date.toString();
      date = date.replace(/,/g, "/");
      return date;
    },
    trunc(x, posiciones = 0) {
      var s = x.toString();
      var l = s.length;
      var decimalLength = s.indexOf(".") + 1;

      if (l - decimalLength <= posiciones) {
        return x;
      }
      // Parte decimal del número
      var isNeg = x < 0;
      var decimal = x % 1;
      var entera = isNeg ? Math.ceil(x) : Math.floor(x);
      // Parte decimal como número entero
      // Ejemplo: parte decimal = 0.77
      // decimalFormated = 0.77 * (10^posiciones)
      // si posiciones es 2 ==> 0.77 * 100
      // si posiciones es 3 ==> 0.77 * 1000
      var decimalFormated = Math.floor(
        Math.abs(decimal) * Math.pow(10, posiciones)
      );
      // Sustraemos del número original la parte decimal
      // y le sumamos la parte decimal que hemos formateado
      var finalNum =
        entera +
        (decimalFormated / Math.pow(10, posiciones)) * (isNeg ? -1 : 1);

      return finalNum;
    },
  },
  mounted() {
    this.modifyPdf();
  },
  created() {
    this.date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
    this.date = this.formatDate(this.date);
  },
};
</script>

<style>
</style>