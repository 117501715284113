<template>
  <v-container fluid class="navbar mt-5 d-none d-sm-flex">
    <v-row class="d-flex align-center">
      <v-col cols="1" class="d-none d-sm-flex"></v-col>
      <v-col cols="9" class="d-flex align-center justify-start">
        <v-row class="d-flex align-center justify-center">
          <a
            href="https://tiendarevalora.cl/tienda/"
            class="item text-md-body-2 text-lg-body-1"
            >Todos los productos</a
          >
          <a
            href="https://tiendarevalora.cl/categoria/muebles/"
            class="item text-md-body-2 text-lg-body-1"
            >Muebles</a
          >
          <a
            href="https://tiendarevalora.cl/categoria/terraza-y-jardin/"
            class="item text-md-body-2 text-lg-body-1"
            >Terraza y Jardín</a
          >
          <a
            href="https://tiendarevalora.cl/categoria/decoracion-y-accesorios/"
            class="item text-md-body-2 text-lg-body-1"
            >Decoración y Accesorios</a
          >
          <a
            href="https://tiendarevalora.cl/categoria/comercial/"
            class="item text-md-body-2 text-lg-body-1"
            >Comercial</a
          >
          <a
            href="https://tiendarevalora.cl/inspiracion/"
            class="item text-md-body-2 text-lg-body-1"
            >Inspiración</a
          >
          <a
            href="https://tiendarevalora.cl/deck/"
            class="item text-md-body-2 text-lg-body-1"
            >Deck</a
          >
          <a
            href="https://tiendarevalora.cl/proyectos-especiales/"
            class="item text-md-body-2 text-lg-body-1"
            >Proyectos Especiales</a
          >
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="#fffbb3"
          class="text-capitalize font-weight-bold sale-btn"
          rounded
          elevation="0"
          ><a
            href="https://tiendarevalora.cl/categoria/sale/"
            class="sale-link text-md-body-2 text-lg-body-1"
            >Sale!</a
          ></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
export default {
  name: "NavbarComponent",
};
</script>
  
<style lang="sass" scoped>
.navbar
  background-color: #008789
  .item
    color: white
    font-weight: 700!important
    font-size: 0.8rem
    padding: 10px 0
    margin: 0 11px 0 0
    text-decoration: none
    &:hover
      color: #fffbb3
  .sale-btn
    width: 115.734px
    .sale-link
      color: black
      text-decoration: none
</style>