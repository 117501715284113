<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <v-icon class="ml-4 icon" @click="openSidebar">mdi-menu</v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { bus } from "@/main";
export default {
  name: "TopMenu",
  data: () => ({
    sidebar: false,
  }),
  methods: {
    openSidebar() {
      this.sidebar = !this.sidebar;
      bus.$emit("sidebar", this.sidebar);
    },
  },
  created() {
    bus.$on("closeSidebar", (data) => {
      this.sidebar = data;
    });
  },
};
</script>

<style lang="sass" scoped>
.icon
  color: black
</style>