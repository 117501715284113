import Vue from "vue";
import Vuex from "vuex";

import result from "@/store/modules/result";
import table from "@/store/modules/table";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    result,
    table,
  },
});
