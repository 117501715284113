import axios from "axios";

export const httpClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "/api"
      : "https://sandbox.thegrouplab.com/api",
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    // "x-api-key": "JRiCaJoGXs8TB73F6SxMd2ec5E51nfOrMGJ5aw9g", //dev
    "x-api-key": "s7zuNSfufHai8Llagm5OF7iYnNE4LvsO7wlf8Dgy", //prod
    // "x-api-key": "fdjjDVBFb57AjWmNtTLqX1LeRAE9vSvI7cfXB7Gf", //qa
  },
});
export default httpClient;
