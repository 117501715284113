<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h1>Cubicador de deck para pisos</h1>
      </v-col>
      <v-col cols="12">
        <p class="text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h5">
          Calcula la cantidad de tablas necesarias para construir tu terraza.
          Para un cálculo estimado de deck, debes especificar las medidas
        </p>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <cubicator-image
        :showLength="lengthLine"
        :showWidth="widthLine"
        :showAdditionalMeters="AdditionalMetersArea"
        :length="length"
        :width="width"
        :additionalMeters="additionalMeters"
      ></cubicator-image>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-hover v-model="showLength" v-slot="{ hover }">
          <revalora-text-field
            ref="length"
            label="Largo (metros)"
            :hideSpinButtons="true"
            :outlined="true"
            v-model="length"
            :formatters="[formatters.formatNumberInput]"
            :hover="hover"
            @focus="focusLength"
            @blur="blurLength"
          ></revalora-text-field>
        </v-hover>
      </v-col>
      <v-col cols="4">
        <v-hover v-model="showWidth" v-slot="{ hover }">
          <revalora-text-field
            label="Ancho (metros)"
            :hideSpinButtons="true"
            :outlined="true"
            v-model="width"
            :formatters="[formatters.formatNumberInput]"
            :hover="hover"
            @focus="focusWidth"
            @blur="blurWidth"
          ></revalora-text-field>
        </v-hover>
      </v-col>
      <v-col cols="4">
        <v-hover v-model="showAdditionalMeters" v-slot="{ hover }">
          <revalora-text-field
            label="M² adicionales"
            :hideSpinButtons="true"
            :outlined="true"
            v-model="additionalMeters"
            :formatters="[formatters.formatNumberInput]"
            :hover="hover"
            @focus="focusAdditionalMeters"
            @blur="blurAdditionalMeters"
          ></revalora-text-field>
        </v-hover>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <v-btn color="primary" x-large @click="ceiling" :disabled="!enableResult"
        >Ver resultado</v-btn
      >
    </v-row>
    <v-row class="justify-center align-center">
      <v-col cols="12">
        <cubicator-result
          :result="result"
          :length="length"
          :width="width"
          :additionalMeters="additionalMeters"
          :framework="framework"
          :showResult="showResult"
          v-show="showResult"
          :attachment="attachment"
        ></cubicator-result>
      </v-col>
    </v-row>
    <v-row class="footer"> </v-row>
  </v-container>
</template>

<script>
import CubicatorImage from "./CubicatorImage.vue";
import CubicatorResult from "./CubicatorResult.vue";
import RevaloraTextField from "../UI/RevaloraTextField";
import formatters from "@/mixins/formatters";
import { mapMutations } from "vuex";
export default {
  name: "CubicatorTool",
  mixins: [formatters],
  components: {
    CubicatorResult,
    CubicatorImage,
    RevaloraTextField,
  },
  computed: {
    lengthLine() {
      return this.focusedLength || this.showLength;
    },
    widthLine() {
      return this.focusedWidth || this.showWidth;
    },
    AdditionalMetersArea() {
      return this.focusedAdditionalMeters || this.showAdditionalMeters;
    },
    enableResult() {
      return this.length > 0 && this.width > 0;
    },
  },
  data: () => ({
    width: 0,
    length: 0,
    additionalMeters: 0,
    result: 0,
    showWidth: false,
    focusedWidth: false,
    showLength: false,
    focusedLength: false,
    showAdditionalMeters: false,
    focusedAdditionalMeters: false,
    showResult: false,
    framework: 0,
    attachment: 0,
  }),
  methods: {
    ...mapMutations([
      "result/setLength",
      "result/setWidth",
      "result/setAdditionalMeters",
      "result/setResult",
      "result/setFramework",
      "result/setAttachment",
    ]),
    ceiling() {
      let m2 = parseFloat(this.width) * parseFloat(this.length);
      m2 = m2 + parseFloat(this.additionalMeters);
      let ceiling = 4 * m2;
      this.result = Math.ceil(ceiling);
      this.framework = Math.ceil(m2 * 2);
      this.attachment = Math.ceil(m2 * 30);
      this.showResult = true;
      this.setResultParams();
    },
    setResultParams() {
      this["result/setLength"](this.length);
      this["result/setWidth"](this.width);
      this["result/setAdditionalMeters"](this.additionalMeters);
      this["result/setResult"](this.result);
      this["result/setFramework"](this.framework);
      this["result/setAttachment"](this.attachment);
    },
    focusLength() {
      this.focusedLength = true;
    },
    focusWidth() {
      this.focusedWidth = true;
    },
    focusAdditionalMeters() {
      this.focusedAdditionalMeters = true;
    },
    blurLength() {
      this.focusedLength = false;
    },
    blurWidth() {
      this.focusedWidth = false;
    },
    blurAdditionalMeters() {
      this.focusedAdditionalMeters = false;
    },
  },
};
</script>

<style lang="sass">
p
  font-weight: 400!important
.footer
  height: 100px
</style>