const state = {
  tableList: [
    {
      name: "Lisa (30x90)",
      price: 7900,
      frameworkPrice: 8900,
      frameworkSku: "TR06",
      frameworkName: "Lisa (38x90)",
      sku: "TR05",
      sale: [],
      imageSrc: "https://revalora.s3.amazonaws.com/table-images/lisa.png",
    },
    {
      name: "Lisa (38x90)",
      price: 8900,
      frameworkPrice: 8900,
      frameworkName: "Lisa (38x90)",
      frameworkSku: "TR06",
      sku: "TR06",
      // sale: [{ colorId: 2, colorName: "Gris", discount: 0.3 }],
      sale: [],
      imageSrc: "https://revalora.s3.amazonaws.com/table-images/lisa-90x38.png",
    },
    {
      name: "Ranurada (30x90)",
      price: 9500,
      frameworkPrice: 8900,
      frameworkName: "Lisa (38x90)",
      frameworkSku: "TR06",
      sku: "TR05R",
      sale: [],
      imageSrc: "https://revalora.s3.amazonaws.com/table-images/ranurada.png",
    },
    {
      name: "Ranurada (38x90)",
      price: 10500,
      frameworkName: "Lisa (38x90)",
      frameworkSku: "TR06",
      frameworkPrice: 8900,
      sku: "TR06R",
      // sale: [{ colorId: 2, colorName: "Gris", discount: 0.3 }],
      sale: [],
      imageSrc:
        "https://revalora.s3.amazonaws.com/table-images/rugosa-90x38.png",
    },
  ],
  colorList: [
    { id: 4, name: "Natural", colorCode: "#5d685f", skuColor: "X" },
    { id: 1, name: "Negro", colorCode: "#000000", skuColor: "N" },
    { id: 2, name: "Gris", colorCode: "#808080", skuColor: "G" },
    { id: 3, name: "Café", colorCode: "#513c08", skuColor: "C" },
  ],
  attachment: { name: "Fijaciones", sku: "DFIJ01", price: 200 },
  tableType: { name: "Lisa (30x90)", price: 7990, sku: "TR05", sale: [] },
  selectedColor: { id: 2, name: "Gris", colorCode: "#808080", skuColor: "G" },
};

const mutations = {
  setSelectedTable: (state, table) => {
    state.tableType = table;
  },
  setSelectedColor: (state, color) => {
    state.selectedColor = color;
  },
};

const getters = {
  getTables: (state) => state.tableList,
  getSelectedTable: (state) => state.tableType,
  getAttachment: (state) => state.attachment,
  getColors: (state) => state.colorList,
  getSelectedColor: (state) => state.selectedColor,
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
