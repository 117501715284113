<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="pt-6">
        <v-card-title class="text-h5 text-center mb-5" v-if="formOk">
          <v-row class="justify-center align-center">
            Tu correo ha sido enviado con éxito
          </v-row>
        </v-card-title>
        <v-card-title class="text-h5 text-center mb-5" v-if="!formOk">
          <v-row class="justify-center align-center">
            No se pudo enviar tu correo
          </v-row>
        </v-card-title>
        <v-card-text class="text-sm-body-2 text-md-body-1" v-if="formOk"
          >Para continuar y seguir navegando en la página web presiona el botón
          continuar.</v-card-text
        >
        <v-card-text class="text-sm-body-2 text-md-body-1" v-if="!formOk"
          >Algo ocurrió al enviar tu correo, inténtalo nuevamente presionando el
          botón de volver atrás y reenvía tu correo.</v-card-text
        >
        <v-card-actions>
          <v-row class="justify-center align-center mb-5">
            <v-btn color="primary" dark @click="finishForm">
              {{ formOk ? "continuar" : "volver a atrás" }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "infoDialog",
  props: {},
  data: () => ({
    dialog: false,
    formOk: false,
  }),
  methods: {
    finishForm() {
      if (!this.formOk) {
        bus.$emit("retryForm");
      }
      this.dialog = false;
    },
  },
  created() {
    bus.$on("showInfoDialog", (info) => {
      (this.dialog = info.showInfoDialog), (this.formOk = info.formOk);
    });
  },
};
</script>

<style>
</style>