<template>
  <v-navigation-drawer
    v-model="showSidebar"
    absolute
    left
    temporary
    width="260"
  >
    <v-row class="justify-center align-center mt-10 mb-8">
      <a href="https://tiendarevalora.cl/">
        <v-img :src="logo" height="64px" max-width="64px"></v-img>
      </a>
    </v-row>
    <v-list>
      <v-list-item link href="https://tiendarevalora.cl/tienda/">
        <v-list-item-content>
          <v-list-item-title><b>Ver todos los productos</b></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title><b>Muebles</b></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="mueble in muebles"
          :key="mueble.title"
          link
          :href="mueble.link"
        >
          <v-list-item-content class="pl-4">
            <v-list-item-title v-text="mueble.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title><b>Terraza y Jardín</b></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="terraza in terrazas"
          :key="terraza.title"
          link
          :href="terraza.link"
        >
          <v-list-item-content class="pl-4">
            <v-list-item-title v-text="terraza.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item
        link
        href="https://tiendarevalora.cl/categoria/decoracion-y-accesorios/"
      >
        <v-list-item-content>
          <v-list-item-title><b>Decoración y Accesorios</b></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title><b>Comerciales</b></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="comercial in comerciales"
          :key="comercial.title"
          link
          :href="comercial.link"
        >
          <v-list-item-content class="pl-4">
            <v-list-item-title v-text="comercial.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item link href="https://tiendarevalora.cl/inspiracion/">
        <v-list-item-content>
          <v-list-item-title><b>Inspiración</b></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link href="https://tiendarevalora.cl/deck/">
        <v-list-item-content>
          <v-list-item-title><b>Deck</b></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link href="https://tiendarevalora.cl/proyectos-especiales/">
        <v-list-item-content>
          <v-list-item-title><b>Proyectos Especiales</b></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link href="https://tiendarevalora.cl/sale/">
        <v-list-item-content>
          <v-list-item-title><b>Sale!</b></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><b>Legal</b></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        href="https://tiendarevalora.cl/politicas-de-despacho/"
        dense
      >
        <v-list-item-content class="pt-0">
          <v-list-item-title>Políticas de despacho</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        href="https://tiendarevalora.cl/politicas-de-privacidad/"
        dense
      >
        <v-list-item-content class="pt-0">
          <v-list-item-title>Políticas de privacidad</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        href="https://tiendarevalora.cl/terminos-y-condiciones/"
        dense
      >
        <v-list-item-content class="pt-0">
          <v-list-item-title>Términos y condiciones</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        href="https://tiendarevalora.cl/preguntas-frecuentes/"
        dense
      >
        <v-list-item-content class="pt-0">
          <v-list-item-title>Preguntas frecuentes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { bus } from "@/main";
import logo from "@/assets/cropped-marca.png";

export default {
  name: "Sidebar-component",
  watch: {
    showSidebar(newValue) {
      if (!newValue) {
        bus.$emit("closeSidebar", newValue);
      }
    },
  },
  data: () => ({
    showSidebar: false,
    logo,
    items: [
      {
        action: "mdi-ticket",
        title: "Attractions",
      },
      {
        action: "mdi-silverware-fork-knife",
        active: true,
        items: [
          { title: "Breakfast & brunch" },
          { title: "New American" },
          { title: "Sushi" },
        ],
        title: "Dining",
      },
      {
        action: "mdi-school",
        items: [{ title: "List Item" }],
        title: "Education",
      },
      {
        action: "mdi-human-male-female-child",
        items: [{ title: "List Item" }],
        title: "Family",
      },
      {
        action: "mdi-bottle-tonic-plus",
        items: [{ title: "List Item" }],
        title: "Health",
      },
      {
        action: "mdi-briefcase",
        items: [{ title: "List Item" }],
        title: "Office",
      },
      {
        action: "mdi-tag",
        items: [{ title: "List Item" }],
        title: "Promotions",
      },
    ],
    muebles: [
      {
        title: "Sofás y Sillones",
        link: "https://tiendarevalora.cl/categoria/muebles/sofas-y-sillones-muebles/",
      },
      {
        title: "Mesas",
        link: "https://tiendarevalora.cl/categoria/muebles/mesas-muebles/",
      },
      {
        title: "Conjuntos",
        link: "https://tiendarevalora.cl/categoria/muebles/conjuntos-muebles/",
      },
      {
        title: "Ver todo Muebles",
        link: "https://tiendarevalora.cl/categoria/muebles/muebles/",
      },
    ],
    terrazas: [
      {
        title: "Maceteros y Jardineras",
        link: "https://tiendarevalora.cl/categoria/terraza-y-jardin/maceteros-y-jardineras/",
      },
      {
        title: "Mascotas",
        link: "https://tiendarevalora.cl/categoria/terraza-y-jardin/mascotas/",
      },
      {
        title: "Sofás y Sillones",
        link: "https://tiendarevalora.cl/categoria/terraza-y-jardin/sofas-y-sillones-terraza-y-jardin/",
      },
      {
        title: "Mesas",
        link: "https://tiendarevalora.cl/categoria/terraza-y-jardin/mesas-terraza-y-jardin/",
      },
      {
        title: "Conjuntos",
        link: "https://tiendarevalora.cl/categoria/terraza-y-jardin/conjuntos-terraza-y-jardin/",
      },
      {
        title: "Ver todo Terraza y Jardín",
        link: "https://tiendarevalora.cl/categoria/terraza-y-jardin/",
      },
    ],
    comerciales: [
      {
        title: "Parque y Juegos",
        link: "https://tiendarevalora.cl/categoria/comercial/parque-y-juegos/",
      },
      {
        title: "Puntos Limpios y Contenedores",
        link: "https://tiendarevalora.cl/categoria/comercial/puntos-limpios-y-contenedores/",
      },
      {
        title: "Mesas y Sillas",
        link: "https://tiendarevalora.cl/categoria/comercial/mesas-y-sillas/",
      },
      {
        title: "Otros",
        link: "https://tiendarevalora.cl/categoria/comercial/otros/",
      },
      {
        title: "Ver todo Comercial",
        link: "https://tiendarevalora.cl/categoria/comercial/",
      },
    ],
  }),
  created() {
    bus.$on("sidebar", (data) => {
      this.showSidebar = data;
    });
  },
};
</script>

<style>
</style>