<template>
  <div>
    <h1>Página de prueba</h1>

    <v-select
      v-model="tableType"
      :items="tableList"
      item-text="name"
      solo
      :menu-props="{ top: true, offsetY: true }"
      return-object
    >
      <template slot="item" slot-scope="data">
        <template>
          <v-row class="justify-space-between w-100">
            <span class="ml-4">
              {{ data.item.name }}
            </span>
          </v-row>
          <span
            class="
              primary--text
              font-weight-bold
              text-uppercase text-caption text-right
              mr-4
            "
            v-if="data.item.sale.length > 0"
          >
            Descuento en color:
            <span v-for="(discount, index) in data.item.sale" :key="index">
              {{ discount.colorName }}
              <span
                v-if="
                  data.item.sale.length > 1 && index < data.item.sale.length - 1
                "
              >
                ,
              </span></span
            ></span
          >
        </template>
      </template>
      <template slot="selection" slot-scope="data">
        <template>
          <v-row class="justify-space-between w-100">
            <span class="ml-4">
              {{ data.item.name }}
            </span>
            <span
              class="
                primary--text
                font-weight-bold
                text-uppercase text-caption text-right
                d-none d-md-flex
                ml-4
              "
              v-if="data.item.sale.length > 0"
            >
              Descuento en color:
              <span v-for="(discount, index) in data.item.sale" :key="index">
                {{ discount.colorName }}
                <span
                  v-if="
                    data.item.sale.length > 1 &&
                    index < data.item.sale.length - 1
                  "
                  >,</span
                ></span
              ></span
            >
          </v-row>
        </template>
      </template></v-select
    >
    <v-row class="justify-center align-start">
      <color-item
        v-for="color in colorList"
        :key="color.id"
        :color="color"
        @showColor="consoleColor"
      ></color-item>
    </v-row>
    <v-dialog
      v-model="showPopup"
      width="500"
      overlay-opacity="0.3"
      @click="showPopup = false"
      @click:outside="showPopup = false"
    >
      <v-card class="popup-card">
        <v-row class="justify-center align-center">
          <v-col>
            <h3 class="text-center mt-5">Tabla {{ tableType.name }}</h3>
            <v-img
              :src="tableType.imageSrc"
              max-height="450"
              max-width="450"
            ></v-img>
            <p class="pl-5" v-if="color">
              SKU:{{ `${tableType.sku}${color.skuColor}` }}
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-hover v-model="showPopup" v-slot="{ hover }">
      <div
        class="popup-hover-container"
        ref="hoverContainer"
        @click="showPopup = !showPopup"
        :hover="hover"
      >
        <v-icon class="pb-7">mdi-information-outline</v-icon>
      </div>
    </v-hover>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ColorItem from "@/components/cubicator/ColorItem";
export default {
  name: "testView",
  components: {
    ColorItem,
  },
  computed: {
    ...mapGetters([
      "table/getTables",
      "table/getSelectedColor",
      "table/getColors",
    ]),
  },
  watch: {
    tableType() {
      this["table/setSelectedTable"](this.tableType);
    },
  },
  data: () => ({
    tableList: [],
    tableType: "",
    colorList: [],
    showPopup: false,
    color: null,
  }),
  methods: {
    ...mapMutations(["table/setSelectedTable", "table/setSelectedColor"]),
    initProps() {
      this.tableList = this.$store.getters["table/getTables"];
      this.tableType = this.tableList[0];
      this.colorList = this["table/getColors"];
      this.color = this["table/getSelectedColor"];
    },
    consoleColor(color) {
      console.log("color desde custom component", color);
    },
  },
  mounted() {
    this.initProps();
  },
};
</script>

<style lang="sass" scoped>
.w-100
  width: 100%
.popup-hover-container
  position: relative
  z-index: 0
  @media screen and ( min-width: 960px )
    z-index: 1000
.popup-card
  overflow: hidden
  color: #A0A0A0
</style>