export const required = (value, errorMessage = "Campo requerido") =>
  (value !== null && value !== "") || errorMessage;

export const email = (value) =>
  /^(([^<>()[\]\\.,; =\s@"]+(\.[^<>()[\]\\.,; =\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  ) || "Formato de correo incorrecto.";

export const maxLength = (
  value,
  maxLength,
  defaultMessage = "Es demasiado largo"
) => {
  if (value.length > maxLength) return defaultMessage;
  return true;
};
export const minLength = (
  value,
  minLength,
  defaultMessage = "Es demasiado corto"
) => {
  if (value.length < minLength) return defaultMessage;
  return true;
};

export default {
  required,
  email,
  maxLength,
  minLength,
};
