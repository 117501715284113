<template>
  <v-text-field
    :value="value"
    @input="updateValue"
    :label="label"
    :type="type"
    :formatters="formatters"
    :hide-spin-buttons="hideSpinButtons"
    :outlined="outlined"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    :rules="rules"
    :maxlength="maxLength"
    :prefix="prefix"
    :hint="hint"
    :persistent-hint="persistentHint"
  />
</template>
  
  <script>
import inputWithFormatter from "@/mixins/inputWithFormatter";
export default {
  name: "RevaloraTextField",
  props: {
    value: {
      required: true,
    },
    type: {
      required: false,
      type: String,
      default: "text",
    },
    hideSpinButtons: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    outlined: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    maxLength: {
      required: false,
      type: Number,
      default: 10000,
    },
    prefix: {
      required: false,
      type: String,
      default: () => "",
    },
    hint: {
      required: false,
      type: String,
    },
    persistentHint: {
      required: false,
      type: Boolean,
    },
  },
  mixins: [inputWithFormatter],
};
</script>