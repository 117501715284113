<template>
  <div
    class="selector-container"
    v-if="selectedColor"
    :class="[
      { 'ml-0': color.id == 4 },
      { 'mr-0': color.id == 4 },
      { 'mr-3': color.id == 4 && selectedTable.sale.length > 0 },
      { 'mx-0': isSale },
    ]"
  >
    <v-hover v-model="showColor" v-slot="{ hover }">
      <div
        class="selector"
        :class="{
          'color-selected': selectedColor.id === color.id,
        }"
        :style="{ 'background-color': color.colorCode }"
        :hover="hover"
        @click="setSelectedColor(color)"
      ></div>
    </v-hover>
    <label>{{ color.name }}</label>
    <v-chip class="" color="primary" small v-if="isSale"> En oferta </v-chip>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ColorItem",
  props: {
    color: {
      type: Object,
      required: true,
    },
  },
  watch: {
    showColor() {
      this.$emit("showColor", {
        color: this.color,
        showColorHover: this.showColor,
      });
    },
  },
  computed: {
    ...mapGetters(["table/getSelectedColor", "table/getSelectedTable"]),
    selectedColor() {
      return this["table/getSelectedColor"];
    },
    selectedTable() {
      return this["table/getSelectedTable"];
    },
    isSale() {
      return this.selectedTable.sale.some(
        (color) => color.colorId === this.color.id
      );
    },
  },
  data: () => ({
    showColor: false,
  }),
  methods: {
    ...mapMutations(["table/setSelectedColor"]),
    setSelectedColor(color) {
      this["table/setSelectedColor"](color);
    },
  },
  mounted() {},
};
</script>

<style lang="sass" scoped>
.popup-card
    overflow: hidden
    color: #A0A0A0
.color-selected
    border: solid
    border-width: 4px
    border-color: #008789
.selector-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 10px
.selector
    height: 30px
    width: 30px
    cursor: pointer
    border-radius: 10%
.black-color
    background-color: #000000
.grey-color
    background-color: #808080
.brown-color
    background-color: #513c08
.natural
    background-color: #5d685f
</style>