<template>
  <v-container>
    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" sm="12" md="8" lg="8" class="">
        <v-file-input
          accept="image/jpeg,image/gif,image/png,application/pdf,image"
          label="Adjuntar archivos"
          multiple
          v-model="files"
          @change="onInput"
          @input="onInput"
        ></v-file-input>
      </v-col>
    </v-row>
    <p>
      Los archivos adjuntos deben ser relacionados con tu proyecto. Ej: Fotos,
      planos, bosquejos, etc.
    </p>
    <v-row class="pt-0">
      <v-col cols="12">
        <v-simple-table v-if="uploadFiles.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nombre de archivos</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(file, index) in uploadFiles" :key="index">
                <td>{{ file.name }}</td>
                <td>
                  <v-row class="justify-center align-center">
                    <v-icon class="icon-pointer" @click="removeFile"
                      >mdi-close</v-icon
                    >
                  </v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "fileInputComponent",
  props: {
    sendData: {
      type: Boolean,
      required: true,
    },
    resetFiles: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    sendData() {
      if (this.sendData) {
        this.emitResponse;
      }
    },
    resetFiles() {
      if (this.resetFiles) {
        this.files = [];
        this.uploadFiles = [];
      }
    },
  },
  data: () => ({
    files: [],
    uploadFiles: [],
  }),
  methods: {
    onInput() {
      this.files.forEach((file) => {
        this.uploadFiles.push(file);
      });
      this.files = [];
      this.$emit("input", this.uploadFiles);
    },
    removeFile(index) {
      this.uploadFiles.splice(index, 1);
      this.$emit("input", this.uploadFiles);
    },
    emitResponse() {
      this.$emit("response", this.uploadFiles);
    },
  },
};
</script>

<style lang="sass" scoped>
.icon-pointer
  cursor: pointer
</style>>