<template>
  <v-container fluid>
    <v-card
      class="mt-10 result-card p-16"
      elevation="0"
      rounded="xl"
      ref="result"
    >
      <v-row class="d-flex justify-start align-start mt-10 pl-10 pr-10">
        <h2>Resultado de la cubicación</h2>
      </v-row>
      <v-row class="mt-5 mb-5 pl-10 d-flex mb-10 pr-10">
        <h4>
          Para la superficie de tu deck necesitas {{ storeResult }} tablas
          SKU:{{ `${tableType.sku}${selectedColor.skuColor}` }}
          <span class="font-weight-light"
            >(3{{
              tableType.sku === "TR05" || tableType.sku === "TR05R" ? "0" : "8"
            }}
            mm x 90 mm)</span
          >
          para tu proyecto.
          <br />
          <span class="font-weight-light"
            >Precio total del deck:
            <span class="font-weight-bold"
              >{{ priceDeck }}
              <span class="font-weight-light">+ IVA</span></span
            ></span
          >
          <br v-if="tableType.sku === 'TR05R' || tableType.sku === 'TR06R'" />
          <span v-if="tableType.sku === 'TR05R' || tableType.sku === 'TR06R'"
            >Incluye fijaciones plásticas.</span
          >
          <br v-if="tableType.sku === 'TR05R' || tableType.sku === 'TR06R'" />
          <span
            class="font-weight-light"
            v-if="tableType.sku === 'TR05R' || tableType.sku === 'TR06R'"
            >Precio de las fijaciones plásticas
            <span class="font-weight-bold"
              >{{ attachmentPrice }}
              <span class="font-weight-light">+ IVA</span></span
            ></span
          >
          <br />
          <span class="info-text">Este cálculo es aproximado</span>
        </h4>
      </v-row>
      <v-checkbox
        class="ml-6"
        label="Deseo incluir entramado a la solicitud de cotización"
        v-model="withFramework"
      ></v-checkbox>
      <v-row class="mt-5 mb-5 pl-10 d-flex mb-10 pr-10" v-if="withFramework">
        <h4>
          Para el entramado de tu deck necesitas {{ storeFramework }} tablas
          SKU: TR06{{ selectedColor.skuColor }}
          <span class="font-weight-light">(38 mm x 90 mm)</span>
          <br />
          <span class="font-weight-light"
            >Precio total del entramado:
            <span class="font-weight-bold">
              {{ priceFramework }}
              <span class="font-weight-light">+ IVA</span></span
            ></span
          >
          <br />
          <span class="info-text">Este cálculo es aproximado</span>
        </h4>
        <h2></h2>
      </v-row>
      <v-row class="justify-center align-center mt-3 mb-5 px-10">
        <h2 class="text-center">
          El costo aproximado de tu proyecto es: {{ totalPrice }}
          <span class="font-weight-light">+ IVA</span>
        </h2>
      </v-row>
    </v-card>

    <v-row class="justify-center align-center mt-16 mb-1" ref="printButton">
      <cubicator-form v-if="showResult"></cubicator-form>
    </v-row>
    <info-dialog></info-dialog>
  </v-container>
</template>

<script>
import CubicatorForm from "../Form/CubicatorForm";
import InfoDialog from "@/components/Form/InfoDialog.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CubicatorResult",
  components: {
    CubicatorForm,
    InfoDialog,
  },
  props: {
    showResult: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    showResult() {
      this.$refs.printButton.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    withFramework() {
      this["result/setWithFramework"](this.withFramework);
    },
  },
  computed: {
    ...mapGetters([
      "table/getSelectedTable",
      "table/getSelectedColor",
      "result/getResult",
      "result/getFramework",
      "result/priceDeck",
      "result/priceFramework",
      "result/attachmentPrice",
      "result/getWithFramework",
      "result/totalPrice",
    ]),
    tableType() {
      return this["table/getSelectedTable"];
    },
    selectedColor() {
      return this["table/getSelectedColor"];
    },
    storeResult() {
      return this["result/getResult"];
    },
    storeFramework() {
      return this["result/getFramework"];
    },
    priceDeck() {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      })
        .format(this["result/priceDeck"])
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
    },
    priceFramework() {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      })
        .format(this["result/priceFramework"])
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
    },
    attachmentPrice() {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      })
        .format(this["result/attachmentPrice"])
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
    },
    storeWithFramework() {
      return this["result/setWithFramework"];
    },
    totalPrice() {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      })
        .format(this["result/totalPrice"])
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
    },
  },
  data: () => ({
    withFramework: false,
  }),
  methods: {
    ...mapMutations(["result/setWithFramework"]),
  },
};
</script>

<style lang="sass" scoped>
h3
  color: black
h2
  color: black
.result-card
  border: solid
  border-color: #008789

.info-text
  color: #008789
  font-size: 1rem
  font-weight: 400
</style>