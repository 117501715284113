import { render, staticRenderFns } from "./CubicatorResult.vue?vue&type=template&id=919b1234&scoped=true&"
import script from "./CubicatorResult.vue?vue&type=script&lang=js&"
export * from "./CubicatorResult.vue?vue&type=script&lang=js&"
import style0 from "./CubicatorResult.vue?vue&type=style&index=0&id=919b1234&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "919b1234",
  null
  
)

export default component.exports