<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Cubicadores</v-card-title>
      <v-divider></v-divider>
      <v-list class="ml-5">
        <v-list-item dense>Deck para pisos</v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="sass">
.item-list
    font-size: 1rem
</style>