<template>
  <v-app>
    <top-bar></top-bar>
    <sidebar></sidebar>
    <navbar></navbar>
    <v-main>
      <breadcrumb></breadcrumb>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/topbar/TopBar";
import Navbar from "@/components/navbar/Navbar";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import Sidebar from "@/components/sidebar/Sidebar.vue";

export default {
  name: "App",
  components: { TopBar, Navbar, Breadcrumb, Sidebar },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
$font-family: "Helvetica Neue", "arial";

.v-application {
  [class*="text-"] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
</style>
