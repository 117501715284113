const noZeros = (value) => {
  value = value.replace(/^-?0+(?=\d)/, "");
  return value;
};
const formatNumberInput = (value) => {
  //using regex remove all non numeric characters, except for the decimal point and commas. Then replace all commas with decimal points and remove all decimal points except for the first one.
  value = value
    .replace(/[^0-9.,]/g, "")
    .replace(/,/g, ".")
    .replace(/(\..*)\./g, "$1");

  //if the first character is zero, remove it
  if (value.charAt(0) == "0" && value.charAt(1) !== ".") {
    value = value.substr(1);
  }
  //if the value is empty, return zero
  if (value == "") {
    return "0";
  }
  //return the value
  return value;
};

const noMinusSign = (value) => {
  value = value.replace(/-/g, "");
  return value;
};

const onlyDigits = (value) => {
  value = value.replace(/\D/g, "");
  return value;
};

export default {
  noZeros,
  noMinusSign,
  formatNumberInput,
  onlyDigits,
};
