<template>
  <v-container class="bread-crumb">
    <v-row>
      <v-col cols="1"></v-col>
      <a
        class="bread-crumb-link text-mb-body-2 text-lg-body-1"
        href="https://tiendarevalora.cl/"
        >Inicio</a
      ><span class="bread-crumb-item text-mb-body-2 text-lg-body-1"
        >/ Cubicador</span
      >
    </v-row>
  </v-container>
</template>
  
  <script>
export default {
  name: "BreadCrumb",
};
</script>
  
<style lang="sass" scoped>
.bread-crumb
  padding: 30px
  .bread-crumb-link
    text-decoration: none
    color: black
    font-size: 0.8rem
  .bread-crumb-item
    font-weight: 700
    font-size: 0.8rem
    margin-left: 5px
</style>