import table from "./table";

const state = {
  length: null,
  width: null,
  additionalMeters: null,
  result: null,
  framework: null,
  attachment: null,
  withFramework: false,
};

const mutations = {
  setLength: (state, length) => {
    state.length = length;
  },
  setWidth: (state, width) => {
    state.width = width;
  },
  setAdditionalMeters: (state, additionalMeters) => {
    state.additionalMeters = additionalMeters;
  },
  setResult: (state, result) => {
    state.result = result;
  },
  setFramework: (state, framework) => {
    state.framework = framework;
  },
  setAttachment: (state, attachment) => {
    state.attachment = attachment;
  },
  setWithFramework: (state, withFramework) => {
    state.withFramework = withFramework;
  },
};

const getters = {
  getLength: (state) => state.length,
  getWidth: (state) => state.width,
  getAdditionalMeters: (state) => state.additionalMeters,
  getResult: (state) => state.result,
  getFramework: (state) => state.framework,
  getAttachment: (state) => state.attachment,
  getWithFramework: (state) => state.withFramework,
  priceDeck: (state) => {
    let discountColor = table.state.tableType.sale.find(
      (sale) => sale.colorId == table.state.selectedColor.id
    );
    let total;
    let discount = 0;
    if (discountColor) {
      total = table.state.tableType.price * state.result;
      discount = total * discountColor.discount;
      total = total - discount;
      return total;
    } else {
      total = table.state.tableType.price * state.result;
      return total;
    }
  },
  priceFramework: (state) => {
    let discountColor = table.state.tableType.sale.find(
      (sale) => sale.colorId == table.state.selectedColor.id
    );
    let total;
    if (discountColor) {
      total = table.state.tableType.frameworkPrice * state.framework;
      total = total - total * discountColor.discount;
      return total;
    } else {
      total = table.state.tableType.frameworkPrice * state.framework;
      return total;
    }
  },
  attachmentPrice: (state) => {
    return state.attachment * table.state.attachment.price;
  },
  totalPrice: (state, getters) => {
    if (
      table.state.tableType.name.includes("Ranurada") &&
      state.withFramework
    ) {
      return (
        getters.priceFramework + getters.priceDeck + getters.attachmentPrice
      );
    } else {
      if (
        table.state.tableType.name.includes("Ranurada") &&
        !state.withFramework
      ) {
        return getters.priceDeck + getters.attachmentPrice;
      } else {
        if (
          table.state.tableType.name.includes("Lisa") &&
          state.withFramework
        ) {
          return getters.priceFramework + getters.priceDeck;
        } else {
          return getters.priceDeck;
        }
      }
    }
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
