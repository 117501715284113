<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2" lg="2" class="d-none d-lg-flex"></v-col>
      <v-col cols="2" sm="4" lg="2" class="d-none d-sm-flex"
        ><cubicator-menu></cubicator-menu
      ></v-col>
      <v-col cols="12" sm="8" lg="6"><cubicator-tool></cubicator-tool></v-col>
      <v-col cols="2" lg="2" class="d-none d-lg-flex"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import CubicatorMenu from "@/components/cubicator/CubicatorMenu";
import CubicatorTool from "@/components/cubicator/CubicatorTool";
export default {
  name: "CubicatorView",
  components: { CubicatorMenu, CubicatorTool },
};
</script>

<style>
</style>