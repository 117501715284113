<template>
  <v-container fluid class="pt-0 pr-0 pb-0 pl-0 mt-5">
    <v-row no-gutters class="">
      <v-col cols="2" class="d-sm-none align-center justify-center d-flex">
        <top-menu></top-menu>
      </v-col>
      <v-col
        cols="5"
        sm="3"
        md="3"
        class="align-center justify-center d-none d-sm-flex"
      >
        <a href="https://tiendarevalora.cl/">
          <v-img
            :src="logo"
            height="94px"
            max-width="94px"
            class="mr-5"
          ></v-img>
        </a>
      </v-col>
      <v-col
        cols="5"
        sm="6"
        md="6"
        class="d-none d-sm-flex align-center justify-center"
      >
      </v-col>
      <v-col cols="5" sm="3" md="3" class="align-center justify-center d-flex">
      </v-col>
      <v-col
        cols="5"
        sm="3"
        md="3"
        class="align-center justify-center d-flex d-sm-none"
      >
        <a href="https://tiendarevalora.cl/">
          <v-img
            :src="logo"
            height="94px"
            max-width="94px"
            class="mr-5"
          ></v-img>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo from "@/assets/cropped-marca.png";
import TopMenu from "@/components/topbar/TopMenu.vue";

export default {
  name: "TopBar",
  components: {
    TopMenu,
  },
  data: () => ({
    logo: logo,
  }),
};
</script>

<style lang="sass" scoped>
.top-bar
  height: 200px
</style>