export default {
  props: {
    formatters: {
      required: false,
      type: Array,
      default: () => [],
    },
    rules: {
      required: false,
      type: Array,
      default: () => [],
    },
    label: {
      required: false,
      type: String,
      default: () => "",
    },
    counter: {
      required: false,
      type: Number | String,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit(
        "input",
        this.formatters.reduce((a, b) => b(a), value)
      );
    },
  },
};
