import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const revalora = {
  primary: "#008789", // primary main
  primarylight: "#008789", // primary light
  primarydark: "#008789", // primary dark
  secondary: "#61cdb4", // secondary main
  secondarylight: "#61cdb4", // secondary light
  secondarydark: "#61cdb4", // secondary dark
  anchor: "#fffbb3", // link
};

export const theme = {
  ...revalora,
};

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ...theme,
      },
    },
  },
});
