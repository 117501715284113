var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedColor)?_c('div',{staticClass:"selector-container",class:[
    { 'ml-0': _vm.color.id == 4 },
    { 'mr-0': _vm.color.id == 4 },
    { 'mr-3': _vm.color.id == 4 && _vm.selectedTable.sale.length > 0 },
    { 'mx-0': _vm.isSale },
  ]},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"selector",class:{
        'color-selected': _vm.selectedColor.id === _vm.color.id,
      },style:({ 'background-color': _vm.color.colorCode }),attrs:{"hover":hover},on:{"click":function($event){return _vm.setSelectedColor(_vm.color)}}})]}}],null,false,27761345),model:{value:(_vm.showColor),callback:function ($$v) {_vm.showColor=$$v},expression:"showColor"}}),_c('label',[_vm._v(_vm._s(_vm.color.name))]),(_vm.isSale)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v(" En oferta ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }